<template>
  <PageContentLayoutOnly>
    <ListWorkOrderConversations :workOrderID="parseInt(workOrderID)" />
  </PageContentLayoutOnly>
</template>

<script>
import { mapState } from 'vuex'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ListWorkOrderConversations from '@/pages/Shared/communications/Global/List'

import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  name: 'WorkOrderConversations',

  components: {
    PageContentLayoutOnly,
    ListWorkOrderConversations
  },

  data,
  methods,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  watch: {},

  mounted() {
    this.reload()
  },

  i18n: {
    messages: {
      en: { workOrderConversations: { title: 'Conversations' } }
    }
  }
}
</script>

<style></style>
