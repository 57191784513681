<template>
  <Modal :toggle.sync="toggle" :loading="false" :styles="{ modalContent: { minHeight: '100px' } }">
    <template v-slot:header></template>

    <!-- main content of modal -->
    <div>
      <PictureFrame :src="src" />
    </div>

    <template v-slot:footer></template>
  </Modal>
</template>

<script>
/*
    import ImageModal from '@/components/Modals/Image'
    
    <ImageModal 
        ref="imageModal"
    />
    
    this.$refs.imageModal.open ({
        src: ''
    })
*/

import Modal from '@/components/Modal'
import PictureFrame from '@/components/Picture/Frame'

export default {
  components: {
    Modal,
    PictureFrame
  },
  data() {
    return {
      toggle: false,
      src: ''
    }
  },
  methods: {
    open({ src }) {
      console.log('open image modal')
      this.src = src
      this.toggle = true
    }
  }
}
</script>
