import moment from 'moment'

export const data = () => ({
  isDebug: true,
  loading: false,
  photoToggle: false,
  photoList: [],
  workOrderID: 0,
  selectedPhoto: {},
  formatDateFn: function(value) {
    return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
  },

  filters: {
    show: false
  },

  pagination: {
    perPage: 10
  },

  region: {},

  rows: [],

  columns: [
    {},
    {
      field: 'description',
      label: 'Description',
      aria: 'Description',
      width: '65%',
      sortable: true,
      searchable: true
    },
    {
      field: 'createdDate',
      label: 'Created Date',
      width: '20%',
      sortable: true,
      searchable: true
    },
    {},
    {}
  ],

  styles: {
    filterButton: {
      float: 'right',
      marginTop: '4px'
    }
  }
})
