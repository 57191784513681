<template>
  <PageContent :title="$t('workOrdersList.title')">
    <div class="level">
      <div class="level-left">
        <b-button
          tests-id="add-work-order-button"
          @click.prevent="createWorkOrderModal()"
          type="is-primary"
          rounded
          :style="{
            ...(breakpoint === 'desktop'
              ? {}
              : {
                  width: '100%'
                })
          }"
        >
          Add Work Order
        </b-button>
      </div>
      <div class="level-right">
        <b-field label="Status" tabindex="0" aria-label="work order status filter">
          <b-radio v-model="statusFilter" name="name" native-value="all" aria-label="show all">
            All
          </b-radio>
          <b-radio
            v-model="statusFilter"
            name="name"
            native-value="incomplete"
            aria-label="only show incomplete work orders"
          >
            Incomplete
          </b-radio>
          <b-radio
            v-model="statusFilter"
            name="name"
            native-value="complete"
            aria-label="only show complete work orders"
          >
            Complete
          </b-radio>
          <b-radio
            v-model="statusFilter"
            name="name"
            native-value="cancelled"
            aria-label="only show cancelled work orders"
          >
            Cancelled
          </b-radio>
          <b-radio
            :style="{ color: '#0035cc', fontWeight: 'bold' }"
            v-model="statusFilter"
            name="name"
            native-value="requests"
            aria-label="only show work order requests"
          >
            Work Order Requests
          </b-radio>
        </b-field>
      </div>
    </div>
    <div>
      <b-taglist attached>
        <b-tag type="is-info is-light">Open Work Orders:</b-tag>
        <b-tag type="is-info">{{ openWorkOrderCount }}</b-tag>
      </b-taglist>
      <b-taglist attached>
        <b-tag type="is-info is-light">Open Work Order Requests:</b-tag>
        <b-tag type="is-info">{{ openWorkOrderRequestsCount }}</b-tag>
      </b-taglist>
    </div>
    <div v-if="statusFilter && statusFilter === 'requests'" class="is-12 level-right pb-5">
      <b-field label="Requests" tabindex="0" aria-label="work order request filter">
        <b-radio
          v-model="requestsFilter"
          name="name2"
          native-value="all"
          aria-label="show all work order requests"
        >
          All
        </b-radio>
        <b-radio
          v-model="requestsFilter"
          name="name2"
          native-value="pending"
          aria-label="only show pending work order requests"
        >
          Pending
        </b-radio>
        <b-radio
          v-model="requestsFilter"
          name="name2"
          native-value="promoted"
          aria-label="only show promoted work order requests"
        >
          Promoted
        </b-radio>
        <b-radio
          v-model="requestsFilter"
          name="name2"
          native-value="cancelled"
          aria-label="only show cancelled work order requests"
        >
          Cancelled
        </b-radio>
        <b-radio
          v-model="requestsFilter"
          name="name2"
          native-value="rejected"
          aria-label="only show rejected work order requests"
        >
          Rejected
        </b-radio>
      </b-field>
    </div>

    <ModernTable
      :region="region"
      :filters="filters"
      :rows="statusFilter !== 'requests' ? rows : rowsTwoFiltered"
      :columns="statusFilter !== 'requests' ? columns : columnsTwo"
      :pagination="{
        perPage: 12
      }"
      :loading="loading"
    >
    </ModernTable>

    <Modal :toggle.sync="toggle">
      <WorkOrderModal />
    </Modal>
  </PageContent>
</template>

<script>
import PageContent from '@/containers/PageContent'
import ModernTable from '@/components/tables/Modern/Table'
import WorkOrderModal from '@/components/WorkOrderModal'
import Modal from '@/components/Modal'
import { mapState, mapGetters } from 'vuex'
import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  name: 'WorkOrderList',
  components: {
    PageContent,
    ModernTable,
    WorkOrderModal,
    Modal
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser']),
      breakpoint: state => (state && state.win ? state.win.breakpoint : null)
    })
  },

  data,
  methods,

  mounted: function() {
    data, (this.loading = true)
    this.reload()

    this.loading = false

    this.unsubscribe = this.$store.subscribe(mutation => {
      if (mutation.type === 'workorder/workOrderList') {
        const requestCount = this.$store.getters['workorder/count']

        /*
          this should only redetermine the rows,
          if a request happens behind the scenes.....
        */
        if (this.requestCount !== requestCount) {
          this.loading = true
          this.requestCount = requestCount
          this.determineRows()
          this.loading = false
        }
      }
    })
  },

  beforeDestroy: function() {
    this.unsubscribe()
  },

  watch: {
    statusFilter: 'reload',
    requestsFilter: 'filterRequestRows',
    hoaId() {
      this.reload()
    },
    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  i18n: {
    messages: {
      en: {
        workOrdersList: {
          title: 'Work Orders'
        }
      }
    }
  }
}
</script>

<style></style>
