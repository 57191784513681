<template>
  <div id="ellipsiscontrol">
    <b-dropdown aria-role="menu">
      <template #trigger>
        <b-button
          class="ellipsisbutton font-bold button is-info is-light is-small is-outline is-rounded"
          icon-right="menu-down"
          >More Actions</b-button
        >
      </template>
      <div>
        <b-dropdown-item
          class="ellipsis font-bold button is-info is-light is-small is-outline"
          value="askaquestion"
          aria-role="menuitem"
          has-link
          @click="onAskQuestionClick(id)"
        >
          Ask a Question
        </b-dropdown-item>
      </div>
      <div :style="isDeleteDisabled === false ? 'display: block;' : 'display: none;'">
        <b-dropdown-item
          class="ellipsis font-bold button is-info is-light is-small is-outline"
          value="delete"
          aria-role="menuitem"
          has-link
          @click="onDeleteClick()"
        >
          Delete
        </b-dropdown-item>
      </div>
      <div :style="isCancelledDisabled === false ? 'display: block;' : 'display: none;'">
        <b-dropdown-item
          class="ellipsis font-bold button is-info is-light is-small is-outline"
          value="cancel"
          aria-role="menuitem"
          has-link
          @click="onCancelClick(id)"
        >
          Cancel
        </b-dropdown-item>
      </div>
      <div>
        <b-dropdown-item
          class="ellipsis font-bold button is-info is-light is-small is-outline"
          value="transmit"
          aria-role="menuitem"
          has-link
          @click="onTransmitClick(id)"
        >
          Transmit
        </b-dropdown-item>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
/*
  import Ellipsis from '@/components/ellipsis'

  ellipsis: {
    component: Ellipsis,
    props: {
      onAskQuestionClick: () => {
        this.redirectToAskaQuestion(id, _get(entry, 'vendorID', null), vendor)
      },
      onDeleteClick: () => {
        this.confirmDeleteWorkOrder(id, description)
      },
      onCancelClick: () => {
        this.confirmCancelWorkOrder(id, description)
      },
      onTransmitClick: () => {
        this.confirmTransmitWorkOrderNotification(id, description)
      },

      text: 'Ellipsis'
    }
  }
*/

import { mapState } from 'vuex'

export default {
  name: 'Ellipsis',
  components: {},
  props: {
    id: Number,
    isDeleteDisabled: Boolean,
    isCancelledDisabled: Boolean,
    onTransmitClick: Function,
    onAskQuestionClick: Function,
    onDeleteClick: Function,
    onCancelClick: Function
  },
  data() {
    return {
      isDebug: true,
      hoa: null,
      hideShowEllipsis: false
    }
  },
  created() {},
  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },
  watch: {},
  mounted() {
    this.refresh()
  },
  methods: {
    async refresh() {
      if (this.isDebug === true)
        console.debug(
          `accessToken map=, long/lat ${this.myLong},${this.myLat}, zoom=${this.zoomLevel}`
        )
    }
  }
}
</script>
<style lang="scss" scoped>
#ellipsiscontrol {
  .ellipsis {
    cursor: pointer;
    width: 60%;
    text-align: left;
    border: 1px solid #022954;
  }

  .ellipsisbutton {
    width: 120px;
  }
}
</style>
