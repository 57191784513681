import _get from 'lodash/get'
import Button from '@/components/buttons/Button'
import { parseDate } from '@/utilities/Date/parse'
import { workOrderPhotoStore } from '@/services/WorkOrders/WorkOrderPhoto/store'

import Image from './../components/Image'

export const methods = {
  confirmDeletePhoto(id, description) {
    this.$buefy.dialog.confirm({
      title: 'Delete Work Order Photo',
      message: `Are you sure you want to <b>delete</b> the work order photo for: "${description}"?`,
      confirmText: 'Delete Work Order Photo',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deletePhoto(id)
    })
  },

  async deletePhoto(id) {
    await workOrderPhotoStore.dispatch('deleteWorkOrderPhoto', {
      workOrderPhotoID: id,
      done: () => {
        this.loading = false
        this.selectedPhoto = null
        this.reload()
      }
    })
  },

  processPhotoUpdate(photo) {
    this.photoToggle = true
    this.selectedPhoto = photo
  },

  createPhotoModal() {
    this.selectedPhoto = null
    this.photoToggle = true
  },

  async loadCurrentPhoto({ workOrderPhotoID, workOrderID }) {
    try {
      const { returned } = await workOrderPhotoStore.dispatch('getWorkOrderPhotoGetFile', {
        params: {
          workOrderPhotoID,
          asBase64: true
        }
      })

      if (typeof returned === 'string') {
        return {
          src: returned,
          srcLength: returned.length,
          workOrderID,
          workOrderPhotoID
        }
      }
    } catch (e) {
      console.error(e)
    }

    return {
      workOrderPhotoID,
      workOrderID,
      srcLength: 0,
      src: ''
    }
  },

  async determineRows(list) {
    if (list) {
      var dateFormat = 'LL'

      const photos = await Promise.all(
        list.map(entry => {
          const workOrderPhotoID = _get(entry, 'workOrderPhotoID', 0)
          const workOrderID = _get(entry, 'workOrderID', 0)

          return this.loadCurrentPhoto({ workOrderPhotoID, workOrderID })
        })
      )

      console.log('Results', { photos })

      this.photoList = list.map(entry => {
        const description = _get(entry, 'description', '')
        const tempDate = _get(entry, 'createdDate', '')
        const createdDate = parseDate(tempDate, dateFormat)
        const workOrderPhotoId = _get(entry, 'workOrderPhotoID', 0)
        const workOrderID = _get(entry, 'workOrderID', '')

        let src = ''
        if (typeof workOrderID === 'number' && workOrderID >= 1) {
          src = photos.find(photo => {
            return photo.workOrderPhotoID === workOrderPhotoId
          }).src
        }

        return {
          image: {
            component: Image,
            props: {
              src,
              clicked: () => {
                console.log('clicked', { workOrderPhotoId })

                this.$refs.imageModal.open({
                  src
                })
              }
            }
          },
          description: description,
          createdDate: createdDate,
          edit: {
            component: Button,
            props: {
              onClick: () => {
                this.processPhotoUpdate(entry)
              },
              text: 'Edit'
            }
          },
          erase: {
            component: Button,
            props: {
              onClick: () => {
                this.confirmDeletePhoto(workOrderPhotoId, description)
              },
              text: 'Delete'
            }
          }
        }
      })
    }
  },

  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  async loadPhotoList() {
    const { list } = await workOrderPhotoStore.dispatch('getWorkOrderPhotoList', {
      workOrderID: this.workOrderID
    })

    await this.determineRows(list)
  },

  async reload() {
    this.loading = true

    this.workOrderID = this.$route.params.id || 0

    await this.loadPhotoList()

    this.rows = []

    this.loading = false
  }
}
