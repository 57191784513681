import moment from 'moment'
import * as animationData from '@/assets/lottie/loader.json'

var field = {
  width: '100px',
  display: 'inline-block',
  paddingRight: '6px',
  textAlign: 'left'
}

export const data = () => ({
  isDebug: true,
  lottieOptions: { animationData: animationData.default, classNam: 'lottie-grid-load' },
  toggle: false,
  requestCount: 1,
  loading: false,
  selectedWorkOrder: null,
  workOrders: [],
  openWorkOrderCount: 0,
  openWorkOrderRequestsCount: 0,
  statusFilter: 'all',
  requestsFilter: 'pending',
  formatDateFn: function(value) {
    return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
  },

  filters: {
    show: true
  },

  region: {},

  rows: [],

  rowsTwo: [],

  rowsTwoFiltered: [],

  columns: [
    {
      field: 'id',
      numeric: true,
      label: 'ID',
      aria: 'Work Order ID',
      width: '3%',
      sortable: true,
      searchable: true
    },
    {
      field: 'type',
      label: 'Type',
      aria: 'Type',
      width: '15%',
      sortable: true,
      searchable: true
    },
    {
      field: 'vendorName',
      label: 'Vendor',
      aria: 'Vendor',
      width: '15%',
      sortable: true,
      searchable: true
    },
    {
      field: 'description',
      label: 'Description',
      aria: 'Description',
      width: '15%',
      sortable: true,
      searchable: true
    },
    {
      field: 'address',
      label: 'Address',
      aria: 'Address',
      width: '15%',
      sortable: true,
      searchable: true
    },
    {
      field: 'dateCreated',
      date: true,

      label: 'Date Created',
      aria: 'Date Created',
      width: '10%',
      sortable: true,
      searchable: true
    },
    {
      field: 'dateCancelled',
      date: true,

      label: 'Date Cancelled',
      aria: 'Date Cancelled',
      width: '10%',
      sortable: true,
      searchable: true
    },
    {
      field: 'dateCompleted',
      date: true,

      label: 'Date Completed',
      aria: 'Date Completed',
      width: '10%',
      sortable: true,
      searchable: true
    },
    {},
    {}
  ],

  columnsTwo: [
    {
      field: 'description',
      label: 'Category',
      sortable: true,
      searchable: true
    },
    {
      field: 'detailedDescription',
      label: 'Detailed Description',
      sortable: true,
      searchable: true
    },
    {
      field: 'status',
      label: 'Status',
      sortable: false,
      searchable: false,
      width: '10%'
    },
    {
      field: 'submittedByAddress',
      label: 'Submitted By Address',
      sortable: true,
      searchable: true
    },
    {
      field: 'submittedBy',
      label: 'Submitted By',
      sortable: true,
      searchable: true
    },
    {
      field: 'createdDate',
      label: 'Opened On',
      date: true,
      sortable: true,
      searchable: true
    },
    {
      field: 'actions'
    },
    {
      field: 'askAQuestion'
    }
  ],

  styles: {
    formRow: {
      padding: '10px',
      whiteSpace: 'nowrap'
    },
    filterButton: {
      float: 'right',
      marginTop: '4px'
    },
    amountField: Object.assign({}, field, {
      width: '125px'
    })
  }
})
