import moment from 'moment'

var field = {
  width: '100px',
  display: 'inline-block',
  paddingRight: '6px',
  textAlign: 'left'
}

export const data = () => ({
  formComplete: false,
  formData: {},
  loading: true,
  isDebug: true,
  toggle: false,
  completeToggle: false,
  openOnFocus: true,
  addressFilterQuery: null,
  vendorFilterQuery: null,
  isFetching: false,
  workOrder: null,
  locationTypeList: [],
  workOrderTypeList: [],
  addressDropDownList: [],
  vendorDropDownList: [],
  addressData: [],
  vendorData: [],
  showWeekNumber: false,
  workOrderID: 0,
  currentWorkOrder: {},
  defaultCenterLat: 0,
  defaultCenterLng: 0,
  locationTypeText: 'Location Type',
  hideShowMapFlag: false,
  hideShowMapText: 'Show Map',
  accessToken: '',
  formatDateFn: function(value) {
    return value && value != undefined
      ? moment(value.toString(), 'YYYY/MM/DD HH:mm:ss')
          .format('MM-DD-YYYY')
          .toString()
      : ''
  },
  styles: {
    filterButton: {
      float: 'right',
      marginTop: '4px'
    },
    amountField: Object.assign({}, field, {
      width: '125px'
    })
  }
})
