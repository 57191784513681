import _get from 'lodash/get'
import Button from '@/components/buttons/Button'
import { parseDate } from '@/utilities/Date/parse'
import { workOrderNoteStore } from '@/services/WorkOrders/WorkOrderNote/store'

export const methods = {
  confirmDeleteNote(id, description) {
    this.$buefy.dialog.confirm({
      title: 'Delete Work Order Note',
      message: `Are you sure you want to <b>delete</b> the work order note for "${description}"?`,
      confirmText: 'Delete Work Order Note',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteNote(id)
    })
  },

  async deleteNote(id) {
    await workOrderNoteStore.dispatch('deleteWorkOrderNote', {
      workOrderNoteID: id,
      done: () => {
        this.loading = false
        this.selectedNote = null
        this.reload()
      }
    })
  },

  processNoteUpdate(note) {
    this.noteToggle = true
    this.selectedNote = note
  },

  createNoteModal() {
    this.selectedNote = null
    this.noteToggle = true
  },

  determineRows(list) {
    if (list) {
      var dateFormat = 'LL'

      this.notesList = list.map(entry => {
        const workOrderNoteId = _get(entry, 'workOrderNoteID', 0)
        const tempDate = _get(entry, 'createdDate', '')
        const createdDate = parseDate(tempDate, dateFormat)
        const aspNetName = _get(entry, 'aspNetName', '')
        const authenticatedId = _get(entry, 'createdByIdentityInformation.aspNetUserID', false)
        const note = _get(entry, 'note', '')

        const isAuthenticated = authenticatedId && authenticatedId != undefined ? true : false

        return {
          note: note,
          author: aspNetName,
          createdDate: createdDate,
          edit: {
            component: Button,
            props: {
              onClick: () => {
                this.processNoteUpdate(entry)
              },
              text: 'Edit',
              disabled: !isAuthenticated,
              title: !isAuthenticated
                ? 'You do not have permission to change another person`s notes'
                : 'Edit'
            }
          },
          erase: {
            component: Button,
            props: {
              onClick: () => {
                this.confirmDeleteNote(workOrderNoteId, note)
              },
              text: 'Delete',
              disabled: !isAuthenticated,
              title: !isAuthenticated
                ? 'You do not have permission to delete another person`s notes'
                : 'Delete'
            }
          }
        }
      })
    }
  },

  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  async loadNoteList() {
    workOrderNoteStore
      .dispatch('getWorkOrderNoteList', {
        workOrderID: this.workOrderID
      })
      .then(({ list }) => {
        if (list) {
          this.determineRows(list)
        }
      })
  },

  async reload() {
    this.loading = true

    this.workOrderID = this.$route.params.id || 0

    await this.loadNoteList()

    this.rows = []

    this.loading = false
  }
}
