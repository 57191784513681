<template>
  <PageContentLayoutOnly>
    <ModernTable
      :filters="filters"
      :rows="photoList"
      :columns="columns"
      :pagination="pagination"
      :loading="loading"
    >
      <template v-slot:header>
        <div class="pb-5">
          <b-button @click="createPhotoModal()" size="is-small" type="is-primary" rounded>
            Attach Photo
          </b-button>
          <b-button
            type="is-ghost"
            @click="toggleFilters"
            :style="styles.filterButton"
            :class="'filter-button ' + (filters.show ? 'active' : '')"
          >
            <b-icon pack="mdi" icon="filter-outline" size="is-small" style="display: inline-block">
            </b-icon>
            <div style="display: inline-block">Filter</div>
          </b-button>
        </div>
      </template>
    </ModernTable>

    <ImageModal ref="imageModal" />

    <Modal :toggle.sync="photoToggle">
      <PhotosModal :workOrderID="parseInt(workOrderID)" :workOrderPhotoProp="selectedPhoto" />
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
//
import { mapState } from 'vuex'
//
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
//
import ImageModal from '@/components/Modals/Image'
import ModernTable from '@/components/tables/Modern/Table'
import PhotosModal from '@/components/WorkOrderPhotoModal'
import Modal from '@/components/Modal'

import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  name: 'WorkOrderNotes',

  components: {
    PageContentLayoutOnly,
    ModernTable,
    PhotosModal,
    Modal,
    ImageModal
  },

  data,
  methods,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  watch: {
    photoToggle() {
      if (this.photoToggle == false) {
        if (this.isDebug == true) console.debug('...in photoToggle()')
        this.photoToggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
        this.reload()
      }
    }
  },

  mounted() {
    this.reload()
  },

  i18n: {
    messages: {
      en: { workOrderPhotos: { title: 'Photos' } }
    }
  }
}
</script>

<style></style>
