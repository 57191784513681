<template>
  <PageContentLayoutOnly role="region" workorder-details>
    <section class="section card">
      <div
        class="level pl-5 pr-5"
        :style="{
          display: 'flex',
          alignItems: 'center',
          ...(breakpoint === 'desktop'
            ? {}
            : {
                flexDirection: 'column'
              })
        }"
      >
        <div class="pt-5 level-left level-item title">{{ $t('workorders.title') }}</div>
        <div :style="{ display: 'flex', alignItems: 'center' }">
          <svg
            width="30"
            height="30"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 19l-7-7 7-7"
            ></path>
          </svg>
          <b-button label="Back to Work Orders" @click.prevent="returnToParent()" />
        </div>
      </div>

      <LinksMenu
        aria-label="work order detail pages"
        :tabs="tabs"
        prefix="workorder."
        :style="{
          paddingLeft: '20px'
        }"
        :params="{
          id: $route.params.id
        }"
      >
        <section :style="{ overflowX: 'auto', flexGrow: 2 }">
          <SlideTransition :name="transitionName">
            <keep-alive>
              <router-view :key="$route.name" />
            </keep-alive>
          </SlideTransition>
        </section>
      </LinksMenu>
    </section>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState } from 'vuex'
import SlideTransition from '@/components/transitions/Slide.vue'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'

import { data } from './keys/data'
import { methods } from './keys/methods'
import { watch } from './keys/watch'

import LinksMenu from '@/components/menus/Links'

export default {
  components: {
    SlideTransition,
    PageContentLayoutOnly,
    LinksMenu
  },

  data,

  computed: {
    ...mapState({
      unit: state => state.hoa.unit,
      breakpoint: state => state.win.breakpoint
    }),

    debug() {
      return this.unit
    }
  },

  watch,

  mounted() {
    this.reload()
  },

  methods,

  i18n: {
    messages: {
      en: { workorders: { title: 'Work Order Details' } }
    }
  }
}
</script>

<style lang="scss" scoped>
.owner-avatar {
  margin: 1em auto 2em;
}

.card {
  box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.05) !important;

  .card {
    border: 0 !important;
  }
}

.avatar-img {
  border: 1px solid #e7e8f2;
  padding: 0.25em;
}

.address-card {
  h5,
  h6 {
    margin: 0;
  }
}

.details-img {
  img {
    border-radius: 0.5em;
  }
}
</style>
