<template>
  <div
    :style="{
      width: '200px'
    }"
  >
    <PictureFrame :src="src" :clicked="clicked" />
  </div>
</template>

<script>
import PictureFrame from '@/components/Picture/Frame'

export default {
  components: {
    PictureFrame
  },
  props: {
    src: String,
    clicked: Function
  }
}
</script>
